import { Box, useMediaQuery } from '@mui/material'
import { useRequest } from 'ahooks'
import { Button, Row, Col, Select, message } from 'antd'
import React, { memo, useEffect, useState } from 'react'
import { useModalState } from '../../utils'
import { GlobalAntdFormStyle } from '../GlobalCSS/AntdFormStyle'
import { APIqueryBizDictData, APIqueryCountry, APIsubmitEnquirePrice } from './api'
import { Form, FormItem, FormItemProps } from './Form'
import { navigate } from 'gatsby'
import FormModal from './FormModal'
import SelectModalH5 from './SelectModalH5'
import { logEvent } from '@/utils/logEvent'
import { I18nBox } from '../I18nBox'

const { Option } = Select

export const ptBoxInFormItemsx = {
  height: '46px',
  bgcolor: '#f6f8fb',
  borderRadius: '6px',
  px: '16px',
  lineHeight: '46px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

const Index = ({ locale, headerLocale, languageLocale }) => {
  const isPc = useMediaQuery('@media (min-width: 600px)')
  const { onOpen, onClose, visible } = useModalState()
  const selectModalState = useModalState()
  const [form] = Form.useForm()
  const [selectedCallCode, setSelectedCallCode] = useState<string>()
  const [selectedCountry, setSelectedCountry] = useState('')
  const dictRes = useRequest(() =>
    APIqueryBizDictData('WebCountry,NumberofDailyOrders,QuoteProductType,TypeofServiceRequired'),
  )
  // const dailyCountListRes = useRequest(() => APIqueryBizDictData('NumberofDailyOrders'))
  // const productTypeRes = useRequest(() => APIqueryBizDictData('QuoteProductType'))
  // const serviceTypeRes = useRequest(() => APIqueryBizDictData('TypeofServiceRequired'))

  const countryList = dictRes?.data?.resultObject?.WebCountry || []
  const dailyCountList = dictRes.data?.resultObject?.NumberofDailyOrders || []
  const productTypeList = dictRes.data?.resultObject?.QuoteProductType || []
  const serviceTypeList = dictRes.data?.resultObject?.TypeofServiceRequired || []

  const callCodeRes = useRequest(APIqueryCountry, {
    onSuccess: (res) => {
      const list = res?.resultObject
      Array.isArray(list) && setSelectedCountry(list?.[0]?.countryName)
    },
  })
  const callCodeList = callCodeRes?.data?.resultObject || []

  const submitRes = useRequest(APIsubmitEnquirePrice, {
    manual: true,
    onSuccess: (res) => {
      console.log(1, res)
      if (res.status === 'success') {
        navigate('/enquiredSuccess')
      } else {
        message.error(res?.message)
      }
    },
  })

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values)
        submitRes.run({
          ...values,
          interCallingCode: selectedCallCode,
          productType: values.productType?.join(),
        })
        logEvent('点击_首页_咨询问价提交')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const selectBefore = (
    <Box
      component={Select}
      value={selectedCountry}
      disabled={true}
      sx={{
        '.ant-select-selector': {
          border: 'none !important',
          bgcolor: '#f6f8fb !important',
        },
      }}
      suffixIcon={null}
      onSelect={(e) => setSelectedCallCode(e)}
    >
      {callCodeList.map((i, k) => (
        <Option value={i.countryName} key={k}>
          {i.countryName} {i.countryCallingCode}
        </Option>
      ))}
    </Box>
  )

  const formList: FormItemProps[] = [
    {
      type: 'Input',
      name: 'companyName',
      placeholder: locale.Please_enter,
      label: locale.companyName,
      required: true,
      rules: [{ max: 100 }],
      ColProps: { md: 12, xs: 24 },
    },
    {
      type: 'Input',
      name: 'email',
      placeholder: locale.Please_enter,
      label: locale.email,
      rules: [
        {
          pattern: /^[\w\-.]+\@+[a-z0-9]+\.[a-z]{2,4}$/,
          message: locale.please_input_correct_email,
        },
      ],
      required: true,
      ColProps: { md: 12, xs: 24 },
    },

    isPc
      ? {
          type: 'Select',
          name: 'country',
          label: locale.公司所在地,
          placeholder: locale.Please_choose,
          required: true,
          ColProps: { md: 12, xs: 24 },
          data: countryList,
          optionValue: 'key',
          optionLabel: 'value',
          SelectProps: {
            onSelect: (e) => {
              const val = callCodeList.filter((_: any) => _.countryName === e)?.[0]
                ?.countryCallingCode
              setSelectedCallCode(val)
              setSelectedCountry(e)
            },
          },
        }
      : {
          name: 'country',
          label: locale.公司所在地,
          required: true,
          ColProps: { md: 12, xs: 24 },
          children: (
            <Box
              className='ptBoxInFormItem' // 加此样式为了 表单校验错误边框显红
              sx={{
                ...ptBoxInFormItemsx,
                color: form.getFieldValue('country') ? '#151741' : '#d9d9d9',
              }}
              onClick={() =>
                selectModalState.onOpen({
                  title: 'COUNTRY',
                  list: countryList,
                  value: form.getFieldValue('country'),
                  optionValue: 'key',
                  optionLabel: 'value',
                  onSelect: (e) => {
                    form.setFieldsValue({ country: e })
                    const val = callCodeList.filter((_: any) => _.countryName === e)?.[0]
                      ?.countryCallingCode
                    setSelectedCallCode(val)
                    setSelectedCountry(e)
                  },
                })
              }
            >
              {form.getFieldValue('country')
                ? countryList?.filter((_) => _.key === form.getFieldValue('country'))?.[0]?.value
                : locale.Please_choose}
            </Box>
          ),
        },
    {
      type: 'Input',
      name: 'phoneNumber', // TODO: 正则校验--根据不同国家，号码格式及上线不同
      placeholder: locale.Please_enter,
      label: locale.phoneNumber,
      required: true,
      ColProps: { md: 12, xs: 24 },
      InputProps: { addonBefore: selectBefore },
      rules: [
        {
          pattern: new RegExp(
            `^\\d{1,${
              callCodeList?.filter((e) => e.countryCallingCode === selectedCallCode)?.[0]
                ?.phoneLength
            }}$`,
          ),
          message: locale.please_input_correct_phoneNumber,
        },
      ],
    },
    isPc
      ? {
          type: 'Select',
          name: 'dailyOrder',
          label: locale.dailyOrderCount,
          placeholder: locale.Please_choose,
          required: true,
          ColProps: { md: 12, xs: 24 },
          data: dailyCountList,
          optionValue: 'key',
          optionLabel: 'value',
        }
      : {
          name: 'dailyOrder',
          label: locale.dailyOrderCount,
          required: true,
          ColProps: { md: 12, xs: 24 },
          children: (
            <Box
              className='ptBoxInFormItem' // 加此样式为了 表单校验错误边框显红
              sx={{
                ...ptBoxInFormItemsx,
                color: form.getFieldValue('dailyOrder') ? '#151741' : '#d9d9d9',
              }}
              onClick={() =>
                selectModalState.onOpen({
                  title: locale.dailyOrderCount,
                  list: dailyCountList,
                  value: form.getFieldValue('dailyOrder'),
                  optionValue: 'key',
                  optionLabel: 'value',
                  onSelect: (e) => {
                    form.setFieldsValue({ dailyOrder: e })
                  },
                })
              }
            >
              {form.getFieldValue('dailyOrder')
                ? dailyCountList?.filter((_) => _.key === form.getFieldValue('dailyOrder'))?.[0]
                    ?.value
                : locale.Please_choose}
            </Box>
          ),
        },
    form.getFieldValue('country') === 'KSA'
      ? isPc
        ? {
            type: 'Select',
            name: 'needService',
            label: locale.服务类型,
            placeholder: locale.Please_choose,
            required: true,
            ColProps: { md: 12, xs: 24 },
            data: serviceTypeList,
            optionValue: 'key',
            optionLabel: 'value',
          }
        : {
            name: 'needService',
            label: locale.服务类型,
            required: true,
            Colspan: 24,
            children: (
              <Box
                className='ptBoxInFormItem'
                sx={{
                  ...ptBoxInFormItemsx,
                  color: form.getFieldValue('needService') ? '#151741' : '#d9d9d9',
                }}
                onClick={() =>
                  selectModalState.onOpen({
                    title: locale.服务类型,
                    list: serviceTypeList,
                    value: form.getFieldValue('needService'),
                    optionValue: 'key',
                    optionLabel: 'value',
                    onSelect: (e) => {
                      form.setFieldsValue({ needService: e })
                    },
                  })
                }
              >
                {form.getFieldValue('needService')
                  ? serviceTypeList?.filter((_) => _.key === form.getFieldValue('needService'))?.[0]
                      ?.value
                  : locale.Please_choose}
              </Box>
            ),
          }
      : null,
    {
      type: 'Select',
      name: 'productType',
      label: locale.产品类型,
      placeholder: locale.Please_choose,
      required: true,
      ColProps: { md: 24, xs: 24 },
      mode: 'multiple',
      data: productTypeList,
      optionValue: 'key',
      optionLabel: 'value',
    },
  ].filter(Boolean)

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          display: ['null', 'flex'],
          justifyContent: ['unset', 'space-between'],
          '.banner': {
            display: ['none', 'unset'],
            width: ['unset', '540px'],
            backgroundImage: `url('https://imile-minio-cdn.imile.com/imile-home/offical-web/home/requestaquotebg.png')`,
            p: ['unset', '40px 60px 0 40px'],
            '.link_text': { color: '#FFF303', fontSize: ['unset', '16px'], cursor: 'pointer' },
            '.slogan': { color: '#fff', fontSize: ['unset', '36px'], maxWidth: ['unset', '400px'] },
          },
          '.content': {
            width: ['100vw', 'calc(100% - 540px)'],
            minWidth: ['unset', '600px'],
            height: '100%',
            overflow: 'hidden',
            overflowY: 'auto',
            px: ['.4rem', '20px', '20px', '30px', '50px', '80px'],
            py: ['55px', '120px'],
            '.page_title': {
              color: '#000417',
              fontSize: ['22px', '36px'],
              mb: ['.8rem', '80px'],
            },
            '.ant-btn': {
              width: ['100%'],
              height: ['44px', '48px'],
            },
          },
        }}
      >
        <div className='banner'>
          <div className='link_text' onClick={() => navigate('/')}>
            &lt; &nbsp; &nbsp; {headerLocale.homepage}
          </div>
          <div className='slogan'>{locale.Slogan}</div>
        </div>
        <div className='content'>
          <I18nBox languageLocale={languageLocale} />
          <div className='page_title'>{locale.咨询报价}</div>
          <GlobalAntdFormStyle />
          <Form labelCol={{ span: 24 }} form={form} name='form_in_modal'>
            <Row gutter={20}>
              {formList.map((_) => (
                <FormItem {..._} key={_.name} />
              ))}
            </Row>
            <Box component={Row} gutter={20} sx={{ mt: ['.88rem', '80px'] }}>
              <Col md={12} xs={24}>
                {isPc ? (
                  <>
                    <Button key='2' type='primary' loading={submitRes.loading} onClick={onFinish}>
                      {locale.SUBMIT}
                    </Button>
                  </>
                ) : (
                  <Button
                    key='3'
                    type='primary'
                    block
                    loading={submitRes.loading}
                    onClick={onFinish}
                  >
                    {locale.SUBMIT}
                  </Button>
                )}
              </Col>
            </Box>
          </Form>
        </div>
      </Box>

      <SelectModalH5
        currentItem={selectModalState.currentItem}
        visible={selectModalState.visible}
        onClose={selectModalState.onClose}
      />
    </>
  )
}

export default memo(Index)
