import { Box, Grid, useMediaQuery } from '@mui/material'
import { graphql, navigate } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { Header, SEO, useI18next } from '../components'
import QuoteSinglePage from '../components/EnquireForm/QuoteSinglePage'

export default (props) => {
  const isPc = useMediaQuery('@media (min-width: 600px)')
  const {} = useTranslation()
  const { t, languages, changeLanguage, language } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const headerLocale = JSON.parse(props.data.headerLocale.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const homeLocale = JSON.parse(props.data.homeLocale.data)

  return (
    <>
      <SEO
        title={seoLocale.about.title}
        description={seoLocale.about.description}
        pageLocale={pageLocale}
      />
      {!isPc && (
        <Header
          location={props.location}
          headerLocale={JSON.parse(props.data.headerLocale.data)}
          footerLocale={JSON.parse(props.data.footerLocale.data)}
          languageLocale={JSON.parse(props.data.languageLocale.data)}
        />
      )}
      <QuoteSinglePage
        headerLocale={headerLocale}
        locale={homeLocale}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["about"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
    homeLocale: locale(ns: { eq: "home" }, language: { eq: $language }) {
      data
    }
  }
`
