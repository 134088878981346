import { CaretDownOutlined } from '@ant-design/icons'
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Dropdown, Menu } from 'antd'
import { useI18next } from 'gatsby-plugin-react-i18next'
import Cookies from 'js-cookie'
import React from 'react'
import { getDomain } from '../../utils/index'

const languageMap = {
  en_US: 'English',
  ar_SA: 'عربي',
  es_MX: 'español',
  zh_CN: '中文',
  tr_TR: 'Türkçe',
  it_IT: 'Italian',
  pl_PL: 'polski',
}
const langMap = {
  en: 'EN',
  ar: 'عربي',
  mx: 'español',
  zh: '中文',
  tr: 'Türkçe',
  it: 'Italian',
  pl: 'polski',
}

export const I18nBox: React.FC = ({ languageLocale }) => {
  const theme = useTheme()
  const { direction } = theme
  const { language, changeLanguage } = useI18next()
  const domain = getDomain()
  const isPc = useMediaQuery('@media (min-width: 600px)')
  const langtext = languageMap[Cookies.get('EXPRESS_LANG')] || languageLocale.英语

  const locales = [
    {
      text: languageLocale.英语,
      value: 'en',
      onClick: () => {
        changeLanguage('en')
        Cookies.set('EXPRESS_LANG', 'en_US', { domain: domain, path: '/' })
      },
    },
    {
      text: languageLocale.阿拉伯,
      value: 'ar',
      onClick: () => {
        changeLanguage('ar')
        Cookies.set('EXPRESS_LANG', 'ar_SA', { domain: domain, path: '/' })
      },
    },
  ]

  const menu = (
    <Menu>
      {locales.map((i, k) => {
        return (
          <Menu.Item key={i.value} onClick={() => i.onClick()}>
            {i.text}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 99,
        top: '20px',
        left: direction === 'rtl' ? '20px' : 'unset',
        right: direction === 'ltr' ? '20px' : 'unset',
        '.dropdownBtn': {
          cursor: 'pointer',
          height: ['.52rem', '46px'],
          lineHeight: ['.52rem', '46px'],
          textAlign: 'center',
          width: ['2.17rem', '107px'],
          color: '#000318',
          fontSize: ['.28rem', '14px'],
          fontWeight: ['bold', '600'],
          bgcolor: '#F6F8FB',
          borderRadius: ['.26rem', '4px'],
        },
        '.ant-dropdown-menu-item': {
          padding: [null, '10px 24px'],
          fontSize: [null, '14px'],
          lineHeight: [null, '22px'],
        },
      }}
    >
      <Dropdown
        overlay={menu}
        placement='bottomLeft'
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        <div className='dropdownBtn flex align_center justify_center'>
          <span>{langtext}</span>
          <CaretDownOutlined style={{ fontSize: '16px', marginLeft: '10PX' }} />
        </div>
      </Dropdown>
    </Box>
  )
}
